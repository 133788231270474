import { trackEvent } from "modules/analytics";
import { createApp } from "vue";
import { mountApp } from "vue-app";
import DynamicPage from "vue-components/dynamic-page.vue";

const app = createApp(DynamicPage,
    {
        cmsPageEndpointPath: "/index"
    }
);
mountApp(app, "page-app");

trackEvent("entry_view", { item_name: "Virtuoso_Home", item_category: "Virtuoso_Homepage" });
